import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

export const getThreeDCollection = () => async dispatch => {
      dispatch({
            type: actionTypes.THREED_COLLECTION_REQUEST,
      });
      await axios.get("/threeDCollections/last/all")
            .then(response => {
                  dispatch({
                        type: actionTypes.THREED_COLLECTION_SUCCESS,
                        payload: response.data
                  })
            })
            .catch(error => {
                  dispatch({
                        type: actionTypes.THREED_COLLECTION_FAIL,
                        payload: getMessage(error)
                  })
            })
}