import React from 'react';
import moment from 'moment';
import { homeOddPrice, awayOddPrice } from './oddPrice';

const getChoice = (bet) => {
   if (bet.betType === 'BODY' && bet.betChoice === 'LOWER') {
      const team = bet.homeUpper ? 'away' : 'home';
      return bet.match[team].name;
   }
   if (bet.betType === 'BODY' && bet.betChoice === 'UPPER') {
      const team = bet.homeUpper ? 'home' : 'away';
      return bet.match[team].name;
   }
   const choice = bet.betChoice === "UPPER" ? 'ဂိုးပေါ်' : 'ဂိုးအောက်';
   const goalPrice = () => (<div style={{ width: '50px' }}>
      ({bet.odds}{bet.price >= 0 ?
            `+${bet.price}` : bet.price})
   </div>);
   return <>{choice}{goalPrice()}</>;
}

export class MixBetToPrint extends React.Component {
   render() {
      const bet = this.props.bet;
      let title = localStorage.getItem("555mixPOSReceipt");
      let date = moment(bet?.betTime).format('YYYY/MM/DD');
      let time = moment(bet?.betTime).format('hh:mma');
      let betAmount = bet?.userBetAmount;
      let betMatches = bet?.betMatches?.map(el =>
      (<div style={styles.betMatch}>
         <div style={styles.textLeft}>
            <span>{el?.match?.home.name}{el?.betType === 'BODY' && homeOddPrice(el)}</span><br />
            <span>{el?.match?.away.name}{el?.betType === 'BODY' && awayOddPrice(el)}</span>
         </div>
         <div style={styles.textRight}>
            <span>{getChoice(el)}</span>
         </div>
      </div>)
      )
      return (
         <>
            <div style={styles.container}>
               <h1 style={styles.brand}>
                  {title}
               </h1>
               <div style={styles.basicInfo}>
                  <span>Bet ID : {bet?.id}</span>
                  <div style={styles.dateTime}>
                     <span>Date:{date}</span>
                     <span>Time:{time}</span>
                  </div>
               </div>
               <div style={styles.dottedHr} />
               <div style={styles.tbh}>
                  <span style={styles.textLeft}>Match</span>
                  <span style={styles.textRight}>Choice</span>
               </div>
               <div style={styles.dottedHr} />
               {betMatches}
               <div style={styles.dottedHr} />
               <div style={styles.result}>
                  <div style={styles.label}>
                     <span>လောင်းငွေ</span><br />
                     <span>ခန့်မှန်းနိုင်ငွေ</span>
                  </div>
                  <div style={styles.amounts}>
                     <span>{betAmount}</span><br />
                     <span>{this.props.getMaxPayout({
                        amount: betAmount,
                        count: bet?.betMatches?.length
                     })}</span>
                  </div>
               </div>
               <div style={styles.dottedHr} />
            </div>
         </>
      );
   }
}
const styles = {
   container: {
      width: '100%',
      margin: 0,
      padding: 0,
      background: 'white',
      padding: '5px',
      color: 'black',
      fontSize: '9pt',
      fontFamily: "Roboto, sans-serif"
   },
   brand: {
      textAlign: 'center',
      fontSize: '1.5rem',
      color: 'black',
      fontWeight: 'bold'
   },
   basicInfo: {
      display: 'flex',
      justifyContent: 'space-between',
   },
   dateTime: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'right'
   },
   dottedHr: {
      borderTop: '2px dotted #000',
      height: 0,
      width: '100%',
      marginTop: '10px',
      marginBottom: '10px'
   },
   tbh: {
      display: 'flex',
      justifyContent: 'space-between',
   },
   betMatch: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '10px'
   },
   textLeft: {
      textAlign: 'left'
   },
   textRight: {
      textAlign: 'right'
   },
   result: {
      display: 'flex',
   },
   label: {
      flex: 1,
      textAlign: 'right'
   },
   amounts: {
      flex: 1,
      textAlign: 'right'
   }
}