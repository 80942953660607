import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

export const getSingle=()=>async dispatch=>{
    dispatch({
        type:actionTypes.GET_SINGLE_START
    });
    axios.get('/collections/last/singlebet')
         .then(response=>dispatch({
             type:actionTypes.GET_SINGLE_SUCCESS,
             payload:response.data
         }))
         .catch(err=>dispatch({
             type:actionTypes.GET_SINGLE_FAIL,
             payload:getMessage(err)
         }))
}