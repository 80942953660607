import {
      Row, Col, InputNumber,
      Space, Table, message as antdMessage, Input, Button,
      Alert, Spin
} from 'antd';
import {
      SaveOutlined,
      PrinterFilled,
      DeleteOutlined,
} from '@ant-design/icons';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getThreeDCollection } from '../store/actions/threeD/getThreeDCollection';
import { betThreeD } from '../store/actions/threeD/betThreeD';
import { useReactToPrint } from 'react-to-print';
import { ThreeDBetToPrint } from './ThreeDBetToPrint';


const getRange = (number) => {
      let start = 0;
      let range = 100;
      while (true) {
            if (number < range) {
                  break;
            }
            else {
                  range += 100;
                  start = range - 100;
            }
      }
      return [start, range];
}

export function ThreeD() {

      const dispatch = useDispatch();

      const { loading,
            threeDNumbers,
            betting,
            message,
            error,
            threeDCollection,
            savedThreeDBet } = useSelector(state => state.threeD);

      useEffect(() => {
            dispatch(getThreeDCollection());
      }, []);

      let [data, setData] = useState([]);

      const [number, setNumber] = useState(null);

      const numberRef = useRef();

      const amountRef = useRef();

      const [amountInput, setAmountInput] = useState(null);

      const onChange = (e) => {
            const value = e.target.value.toLowerCase();
            setNumber(value);
            if (!value.startsWith("r")) {
                  if (!+value) {
                        antdMessage.warn("ဂဏန်းမှားနေသည်");
                        return;
                  }
                  if (value.length === 3) {
                        amountRef.current.focus();
                  }
            }
            if (value.startsWith("r")) {
                  let num = value.replace("r", "");
                  if (num.length > 0 && !+num) {
                        antdMessage.warn("ဂဏန်းမှားနေသည်");
                        return;
                  }
                  if (value.length === 4) {
                        amountRef.current.focus();
                  }
            }
      }

      const onEnterNumber = () => amountRef.current.focus();

      const addRToData = ({ firstDigit, secondDigit, thirdDigit, betAmount }) => {
            if ((firstDigit === secondDigit) && (firstDigit === thirdDigit)) {
                  const num = '' + firstDigit + secondDigit + thirdDigit;
                  addNumberToData(num, betAmount);
                  return;
            }
            if ((firstDigit === secondDigit) && (firstDigit !== thirdDigit)) {
                  const num1 = '' + firstDigit + secondDigit + thirdDigit;
                  const num2 = '' + firstDigit + thirdDigit + secondDigit;
                  const num3 = '' + thirdDigit + firstDigit + secondDigit;
                  [num1, num2, num3].map(v => addNumberToData(v, betAmount));
                  return;
            }
            if ((firstDigit === thirdDigit) && (firstDigit !== secondDigit)) {
                  const num1 = '' + firstDigit + secondDigit + thirdDigit;
                  const num2 = '' + firstDigit + thirdDigit + secondDigit;
                  const num3 = '' + secondDigit + thirdDigit + firstDigit;
                  [num1, num2, num3].map(v => addNumberToData(v, betAmount));
                  return;
            }
            if ((secondDigit === thirdDigit) && (secondDigit !== firstDigit)) {
                  const num1 = '' + firstDigit + secondDigit + thirdDigit;
                  const num2 = '' + thirdDigit + firstDigit + secondDigit;
                  const num3 = '' + thirdDigit + secondDigit + firstDigit;
                  [num1, num2, num3].map(v => addNumberToData(v, betAmount));
                  return;
            }
            const num1 = '' + firstDigit + secondDigit + thirdDigit;
            const num2 = '' + firstDigit + thirdDigit + secondDigit;
            const num3 = '' + secondDigit + firstDigit + thirdDigit;
            const num4 = '' + secondDigit + thirdDigit + firstDigit;
            const num5 = '' + thirdDigit + firstDigit + secondDigit;
            const num6 = '' + thirdDigit + secondDigit + firstDigit;
            [num1, num2, num3, num4, num5, num6].map(v => addNumberToData(v, betAmount));
      }

      const addNumberToData = (number, betAmount) => {
            const [start, end] = getRange(+number);
            if (threeDNumbers.length > 0) {

                  const slicedRange = threeDNumbers.slice(start, end);

                  if (slicedRange?.length > 0) {
                        const filteredNumber = slicedRange.filter(
                              threeD => threeD.number === (number + ""));

                        let result = filteredNumber[0];

                        if (filteredNumber.length > 0) {
                              if (+result?.status === 3) {
                                    antdMessage.warn(result?.number + " ပိတ်ဂဏန်းမရပါ");
                                    return;
                              }
                              const isExisted = data.some(obj => obj.threeDNumberId === result.id);
                              if (isExisted) {
                                    let newData = data.map((obj) => {
                                          if (obj.threeDNumberId === result.id) {
                                                return {
                                                      ...obj,
                                                      amount: obj.amount + betAmount
                                                }
                                          }
                                          return obj;
                                    });
                                    setData(newData);
                              }
                              else {
                                    setData(oldData => [...oldData,
                                    {
                                          threeDNumberId: result.id,
                                          number: result.number,
                                          amount: betAmount,
                                          winPercent: result.winPercent,
                                    }])
                              }
                        }
                  }
            }
      }

      const onAdd = async (e) => {
            const value = +e.target.value;
            if (value < 100) {
                  antdMessage.warn("ထိုးငွေမလုံလောက်ပါ");
                  return;
            }
            if (number.startsWith("r")) {
                  let threeDNumber = number.replace("r", "");
                  if (threeDNumber.length > 0 && !+threeDNumber) {
                        antdMessage.warn("နံပါတ်မှားနေသည်");
                        return;
                  }
                  let firstDigit = +threeDNumber.charAt(0);
                  let secondDigit = +threeDNumber.charAt(1);
                  let thirdDigit = +threeDNumber.charAt(2);
                  await addRToData({
                        firstDigit,
                        secondDigit,
                        thirdDigit,
                        betAmount: value
                  });
            } else {
                  if (number.length > 0 && !+number) {
                        antdMessage.warn("နံပါတ်မှားနေသည်");
                        return;
                  }
                  await addNumberToData(number, value);
            }
            setAmountInput(null);
            setNumber(null);
            numberRef.current.focus();
      }

      const onSave = async () => {
            if (data.length === 0) {
                  antdMessage.warn("အကွက်ရွေးပါ");
                  return;
            }
            await dispatch(betThreeD({
                  collectionId: threeDCollection?.id,
                  betThreeDNumbers: data
            }));
            setData([]);
            setNumber(null);
            setAmountInput(null);
            numberRef.current.focus();
      }

      const componentRef = useRef();

      const handlePrint = useReactToPrint({
            content: () => componentRef.current,
      });

      const onSaveAndPrint = async () => {
            if (data.length === 0) {
                  antdMessage.warn("အကွက်ရွေးပါ");
                  return;
            }
            await dispatch(betThreeD({
                  collectionId: threeDCollection?.id,
                  betThreeDNumbers: data
            }));
            setData([]);
            setNumber(null);
            setAmountInput(null);
            handlePrint();
      }

      const onRemove = (number) => {
            setData(oldData => oldData.filter(
                  obj => obj.threeDNumberId !== number.threeDNumberId))
      }

      const columns = [
            {
                  title: 'Number',
                  dataIndex: 'number',
                  key: 'number'
            },
            {
                  title: 'winPercent',
                  dataIndex: 'winPercent',
                  key: 'winPercent'
            },
            {
                  title: 'Amount',
                  dataIndex: 'amount',
                  key: 'amount'
            },
            {
                  title: 'Remove',
                  render: (text, record) => <DeleteOutlined onClick={() => onRemove(record)} />,
                  key: 'remove'
            }
      ]

      const showTotal = () => {
            let total = 0;
            data.map(obj => {
                  total += obj.amount;
            })
            return <>{total}</>
      }

      return (
            <>
                  {threeDCollection ? (new Date(threeDCollection.closedTime) > Date.now()
                        ?
                        <>
                              <Row>
                                    <Col span={8}>
                                          <Row justify='end'>
                                                <Col>
                                                      <Space size="large" direction="horizontal">
                                                            <Input
                                                                  ref={numberRef}
                                                                  value={number}
                                                                  placeholder='Number'
                                                                  onChange={onChange}
                                                                  onPressEnter={onEnterNumber}
                                                                  maxLength={4}
                                                                  style={{ width: 150 }} />
                                                            <InputNumber placeholder='Amount'
                                                                  value={amountInput}
                                                                  onChange={value => setAmountInput(value)}
                                                                  ref={amountRef}
                                                                  style={{ width: 150 }}
                                                                  onPressEnter={onAdd} />
                                                      </Space>
                                                </Col>
                                          </Row>
                                    </Col>
                              </Row>
                              <br />
                              <Row>
                                    <Col span={8}>
                                          <Table
                                                style={styles.table}
                                                bordered
                                                pagination={false}
                                                loading={loading}
                                                rowKey={record => record.number}
                                                dataSource={data}
                                                columns={columns}
                                          />
                                    </Col>
                              </Row>
                              <br />
                              <br />
                              <Row>
                                    <Col span={3}>
                                          <Row justify='space-between'>
                                                <Col>
                                                      Total
                                                </Col>
                                                <Col>
                                                      {showTotal()}
                                                </Col>
                                          </Row>
                                    </Col>
                              </Row>
                              <br />
                              <Row>
                                    <Col span={8}>
                                          {error && <Alert message={error} type="error" />}
                                          {message && <Alert message={message} type="success" />}
                                    </Col>
                              </Row>
                              <br />
                              <Row>
                                    <Col span={8}>
                                          <div style={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'space-between'
                                          }}>
                                                <Button
                                                      loading={betting}
                                                      onClick={onSave}
                                                      style={styles.button}
                                                      icon={<SaveOutlined />}>
                                                      Save
                                                </Button>

                                                <Button
                                                      onClick={onSaveAndPrint}
                                                      icon={<PrinterFilled />}
                                                      style={styles.button}>
                                                      SAVE AND PRINT
                                                </Button>

                                                <div style={{ display: 'none' }}>
                                                      <ThreeDBetToPrint
                                                            ref={componentRef}
                                                            bet={savedThreeDBet} />
                                                </div>
                                          </div>
                                    </Col>
                              </Row>
                        </> : <div style={styles.centerText}>ထိုးလို့မရသေးပါ</div>)
                        :<div style={styles.centerText}><Spin /></div>}</>
      )
}

const styles = {
      button: {
            color: '#3F51B5',
            borderColor: '#3F51B5',
            textTransform: 'uppercase',
            minWidth: '100px'
      },
      table: {
            height: '300px',
            overflowY: 'auto'
      },

      centerText: {
            width: '100%',
            height: '300px',
            display: 'grid',
            placeItems: 'center',
            textAlign: 'center'
      }
}