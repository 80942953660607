import update from '../utilities/update';
import { loading } from './shared/loading';
import { error } from './shared/error';
import {
      THREED_COLLECTION_REQUEST,
      THREED_COLLECTION_SUCCESS,
      THREED_COLLECTION_FAIL,
      THREED_BET_REQUEST,
      THREED_BET_SUCCESS,
      THREED_BET_FAIL,
} from '../actions/threeD/actionTypes';

const initialState={
      loading:false,
      betting:false,
      error:null,
      message:null,
      threeDCollection:null,
      threeDNumbers:[],
      savedThreeDBet:null
}

const threeDCollectionSuccess=(state,action)=>{
      return update(state,{
            loading:false,
            threeDCollection:action.payload,
            threeDNumbers:action.payload?.threeDNumbers
      });
}

const threeDBetStart=(state,action)=>{
      return update(state,{
            betting:true,
            error:null,
            message:null,
            savedThreeDBet:null,
      });
}

const threeDBetSuccess=(state,action)=>{
      return update(state,{
            betting:false,
            message:'3D Bet အောင်မြင်ပါသည်',
            savedThreeDBet:action.payload
      })
}

const threeDBetFail=(state,action)=>{
      return update(state,{
            betting:false,
            error:action.payload
      });
}

export const threeDReducer=(state=initialState,action)=>{
      switch(action.type)
      {
            case THREED_COLLECTION_REQUEST:
                  return loading(state,action);
            case THREED_COLLECTION_SUCCESS:
                  return threeDCollectionSuccess(state,action);
            case THREED_COLLECTION_FAIL:
                  return error(state,action);
            case THREED_BET_REQUEST:
                  return threeDBetStart(state,action);
            case THREED_BET_SUCCESS:
                  return threeDBetSuccess(state,action);
            case THREED_BET_FAIL:
                  return threeDBetFail(state,action);
            default:
                  return state;
      }
}