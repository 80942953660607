import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getCurrentUser } from '../user/getCurrentUser';

export const saveBet = (request) => async dispatch => {
   dispatch({
      type: actionTypes.SAVE_BET_START,
   });
   const options = {
      url: '/bets',
      method: 'POST',
      data: request
   }
   await axios(options)
      .then(response => {
         dispatch({
            type: actionTypes.SAVE_BET_SUCCESS,
            payload: response.data
         });
         dispatch(getCurrentUser())
      })
      .catch(error => dispatch({
         type: actionTypes.SAVE_BET_FAIL,
         payload: error.response
      }))
}