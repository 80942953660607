import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import * as actions from '../store';
import Login from '../pages/Login';
import Logout from '../pages/Logout';
import { App } from './App';
import TabContainer from './TabContainer';
import { Test } from '../components/Test';

class ConfigRoute extends React.Component {
    async componentDidMount() {
        await this.props.onAuthStateCheck();
    }
    render() {
        return (<>
            <Switch>
                <PrivateRoute exact path="/">
                    <Redirect from="/" to="/mixBet" />
                </PrivateRoute>
                <PrivateRoute path="/mixBet">
                    <App>
                        <TabContainer />
                    </App>
                </PrivateRoute>
                <PrivateRoute path="/singleBet">
                    <App>
                        <TabContainer />
                    </App>
                </PrivateRoute>
                <PrivateRoute exact path="/currentBet">
                    <App>
                        <TabContainer />
                    </App>
                </PrivateRoute>
                <PrivateRoute exact path="/current34Bet">
                    <App>
                        <TabContainer />
                    </App>
                </PrivateRoute>
                <PrivateRoute exact path="/currentBet/:bet_id/detail">
                    <App>
                        <TabContainer />
                    </App>
                </PrivateRoute>
                <PrivateRoute exact path="/current34Bet/:bet_id/detail">
                    <App>
                        <TabContainer />
                    </App>
                </PrivateRoute>
                <PrivateRoute exact path="/history">
                    <App>
                        <TabContainer />
                    </App>
                </PrivateRoute>
                <PrivateRoute path="/history/:history_id/detail">
                    <App>
                        <TabContainer />
                    </App>
                </PrivateRoute>
                <PrivateRoute path="/setting">
                    <App>
                        <TabContainer />
                    </App>
                </PrivateRoute>
                <PrivateRoute path="/fourD">
                      <App>
                        <TabContainer />
                      </App>
                </PrivateRoute>
                <PrivateRoute path="/threeD">
                      <App>
                        <TabContainer />
                      </App>
                </PrivateRoute>
                {/* <Route path="/test">
                      <Test />
                </Route> */}
                <Route path="/login">
                    <Login />
                </Route>
                <Route path="/logout">
                    <Logout />
                </Route>
            </Switch>
        </>);
    }
}

const mapDispatchToProps = dispatch => ({
    onAuthStateCheck: () => dispatch(actions.authCheckState())
});

export default withRouter(connect(null, mapDispatchToProps)(ConfigRoute));