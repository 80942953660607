import React from 'react';
import { Table, Button } from 'antd';
import {
      PrinterFilled,
      ArrowLeftOutlined,
} from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { ComponentToPrint } from './ComponentToPrint';
import { paidBet } from '../store';
import { ThreeDBetToPrint } from './ThreeDBetToPrint';
import { FourDBetToPrint } from './FourDBetToPrint';

const homeOddPrice = (match) => match.homeUpper ?
      (<span>
            ({match.odds}{match.price >= 0 ?
                  `+${match.price}` : match.price})
      </span>) :
      null;
const awayOddPrice = (match) => match.homeUpper ?
      null :
      (<span>
            ({match.odds}{match.price >= 0 ?
                  `+${match.price}` : match.price})
      </span>);

const columns = [
      {
            title: 'Home',
            key: 'home',
            render: (text, record) => {
                  return <>
                        {`${record.match.home.name}`}
                        {homeOddPrice(record)}
                  </>
            }
      },
      {
            title: 'Goal Score',
            key: 'goalScore',
            render: (text, record) => `(${record.match.homeScore}-${record.match.awayScore})`
      },
      {
            title: 'Away',
            key: 'away',
            render: (text, record) => {
                  return <>
                        {`${record.match.away.name}`}
                        {awayOddPrice(record)}
                  </>
            }
      },
      {
            title: 'Goal Total Price',
            key: 'goalTotalPrice',
            render: (text, record) => record.betType === 'BODY' ? '-' :
                  `(${record.odds}${record.price >= 0 ?
                        `+${record.price}` : record.price})`
      },
      {
            title: 'Bet Choice',
            key: 'betChoice',
            render: (text, record) => getChoice(record)
      }
]

const fourDColumns = [
      {
            title: 'ဂဏန်း',
            dataIndex: 'number',
            key: 'number',
            render: (text, record) => record?.fourDNumber?.number
      },
      {
            title: 'ထိုးငွေ',
            dataIndex: 'userBetAmount',
            key: 'userBetAmount',
            render: (text, record) => record?.userBetAmount
      },
]

const threeDColumns = [
      {
            title: 'ဂဏန်း',
            dataIndex: 'number',
            key: 'number',
            render: (text, record) => record?.threeDNumber?.number
      },
      {
            title: 'အဆ',
            dataIndex: 'winPercent',
            key: 'winPercent',
      },
      {
            title: 'ထိုးငွေ',
            dataIndex: 'userBetAmount',
            key: 'userBetAmount',
      }
]

const getChoice = (bet) => {
      if (bet.betType === 'BODY' && bet.betChoice === 'LOWER') {
            const team = bet.homeUpper ? 'away' : 'home';
            return bet.match[team].name;
      }
      if (bet.betType === 'BODY' && bet.betChoice === 'UPPER') {
            const team = bet.homeUpper ? 'home' : 'away';
            return bet.match[team].name;
      }
      return bet.betChoice;
}

class HistoryDetail extends React.Component {
      render() {
            const { history_id } = this.props.match?.params;
            const filteredBet = this.props.histories?.filter(el => `${el.id}` === history_id);
            const bet = filteredBet.length > 0 ? filteredBet[0] : null;
            const { type } = this.props;
            console.log('type', type)
            return (
                  <>
                        <div style={styles.container}>
                              <div style={styles.buttonWrapper}>
                                    <Button
                                          style={{ marginRight: 'auto' }}
                                          icon={<ArrowLeftOutlined />}
                                          type="primary"
                                          onClick={() => this.props.history.goBack()}
                                    >
                                          Back
                                    </Button>
                              </div>
                              <br />
                              <div style={styles.wrapper}>
                                    <div style={styles.top}>
                                          <span>
                                                Bet : {`${history_id}`}
                                          </span>
                                          <span>
                                                Bet : {bet?.userBetAmount}
                                          </span>
                                          <span>
                                                Commission : {bet?.userCommissionAmount}
                                          </span>
                                          <span>
                                                Win : {bet?.userWinAmount}
                                          </span>
                                          <ReactToPrint
                                                trigger={() => <Button
                                                      icon={<PrinterFilled />}
                                                      style={{ borderColor: 'blue', color: 'blue' }}>
                                                      PRINT
                                                </Button>}
                                                content={() => this.componentRef}
                                          />
                                          <div style={{ display: 'none' }}>
                                                {
                                                      type === 'SOCCER' ?
                                                            <ComponentToPrint
                                                                  ref={el => this.componentRef = el}
                                                                  bet={bet}
                                                                  user={this.props.user}
                                                            /> : type === 'THREED' ?
                                                                  <ThreeDBetToPrint
                                                                        ref={el => this.componentRef = el}
                                                                        bet={bet} /> :
                                                                  <FourDBetToPrint
                                                                        ref={el => this.componentRef = el}
                                                                        bet={bet} />}
                                          </div>
                                          <Button
                                                onClick={() => this.props.onChangePaidStatus({ id: history_id, paid: !bet?.paid })}
                                                style={{
                                                      color: 'white',
                                                      background: bet?.paid ? '#FF5252' : '#4CAF50',
                                                      textTransform: 'uppercase'
                                                }}>
                                                {bet?.paid ? `unpaid` : 'paid'}
                                          </Button>
                                    </div>
                                    <Table
                                          columns={type === 'SOCCER' ? columns : type === 'THREED'
                                                ? threeDColumns : fourDColumns}
                                          dataSource={type === 'SOCCER' ? bet?.betMatches : type === 'THREED'
                                                ? bet?.betThreeDNumbers : bet?.betFourDNumbers}
                                    />
                              </div>
                        </div>
                  </>
            );
      }
}

const mapStateToProps = state => ({
      histories: state.history.all,
      user: state.user.current,
      type: state.history.type,
})

const mapDispatchToProps = dispatch => ({
      onChangePaidStatus: ({ id, paid }) => dispatch(paidBet({ id, paid }))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HistoryDetail));

const styles = {
      container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '5px'
      },
      wrapper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: '15px',
            borderRadius: '5px',
            boxShadow: '0px 2px 8px -1px gray',
            width: '75vw',
            minWidth: '500px',
            padding: '15px',
            boxSizing: 'border-box'
      },
      top: {
            display: 'flex',
            gap: '60px',
            fontSize: '16px'
      },
      buttonWrapper: {
            width: '75vw',
            minWidth: '500px',
      }
}