import update from '../utilities/update';
import {loading} from './shared/loading';
import {error} from './shared/error';
import {
    GET_SINGLE_START,
    GET_SINGLE_SUCCESS,
    GET_SINGLE_FAIL
} from '../actions/single/actionTypes';

const initialState={
    loading:false,
    error:null,
    message:null,
    all:null
}

const getSingleSuccess=(state,action)=>{
    return update(state,{
        loading:false,
        all:action.payload
    })
}

const singleReducer=(state=initialState,action)=>{
    switch(action.type)
    {
        case GET_SINGLE_START:
            return loading(state,action);
        case GET_SINGLE_SUCCESS:
            return getSingleSuccess(state,action);
        case GET_SINGLE_FAIL:
            return error(state,action);
        default:
            return state;
    }
}

export default singleReducer;