import {
    LogoutOutlined,
} from '@ant-design/icons';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';

export function Header(){
    const history=useHistory();
    const logout=()=>history.push("/logout");
    const user=useSelector(state=>state.user.current);
    return (
        <>
            <div style={styles.container}>
                <span style={styles.brandName}>555Mix POS</span>
                <div style={styles.profile}>
                    <span>{user?.username}</span>
                    <span>Balance:{user?.balance}</span>
                    <span><LogoutOutlined onClick={logout}/></span>
                </div>
            </div>
        </>
    );
}

const styles={
    container:{
        width:'100%',
        height:'45px',
        background:'#1976D2',
        color:'white',
        paddingLeft:'20px',
        paddingRight:'20px',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center'
    },
    brandName:{
        fontSize:'22px',
    },
    profile:{
        display:'flex',
        columnGap:'20px',
        fontSize:'16px'
    }
}