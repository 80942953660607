import React from 'react';
import { Table, Input, message } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getActiveBets } from '../store';

const columns = [
    {
        title: 'Bet ID',
        dataIndex: 'id',
        key: 'betId'
    },
    {
        title: 'Bet Amount',
        dataIndex: 'userBetAmount',
        key: 'userBetAmount'
    },
    {
        title: 'Win Amount',
        dataIndex: 'userWinAmount',
        key: 'userWinAmount'
    },
    {
        title: 'User Commission',
        dataIndex: 'userCommissionAmount',
        key: 'userCommissionAmount'
    },
    {
        title: 'Action',
        key: 'action',
        render: (text, record) => <Link
            to={`/currentBet/${record.id}/detail`}>
            <EyeOutlined style={{ fontSize: 18 }} />
        </Link>
    }
]

class BetList extends React.Component {
    state = {
        searchKey: "",
        searchResult: []
    }
    componentDidMount() {
        this.props.onGetActive();
    }
    componentDidUpdate() {
        if (this.props.error) {
            message.error(this.props.error);
        }
    }
    onSearch = ({ target: { value } }) => {
        const result = this.props.bets?.filter(el => `${el.id}`.includes(value));
        this.setState(oldState => ({
            ...oldState,
            searchResult: result,
            searchKey: value
        }))
    }
    render() {
        return (
            <>
                <div style={styles.container}>
                    <div style={styles.wrapper}>
                        <Input.Search
                            style={styles.search}
                            placeholder="Search"
                            onChange={this.onSearch}
                            enterButton />
                        <Table
                            columns={columns}
                            dataSource={this.state.searchKey ?
                                this.state.searchResult : this.props.bets}
                            loading={this.props.loading}
                        />
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.activeBet.loading,
    error: state.activeBet.error,
    bets: state.activeBet.all,
})

const mapDispatchToProps = dispatch => ({
    onGetActive: () => dispatch(getActiveBets())
})

export default connect(mapStateToProps, mapDispatchToProps)(BetList);

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        padding: '5px'
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: '15px',
        borderRadius: '5px',
        boxShadow: '0px 2px 8px -1px gray',
        width: '75vw',
        minWidth: '500px',
        padding: '15px'
    },
    search: {
        width: '220px',
        marginLeft: 'auto',
    }
}