import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

export const getHistory=({
    date,
    type,
    status
})=>async dispatch=>{
    dispatch({
        type:actionTypes.GET_HISTORY_START
    });
    axios.get(`/bets/history?date=${date}&type=${type}&status=${status}`)
         .then(response=>dispatch({
             type:actionTypes.GET_HISTORY_SUCCESS,
             payload:response.data
         }))
         .catch(err=>dispatch({
             type:actionTypes.GET_HISTORY_FAIL,
             payload:getMessage(err)
         }))
}