import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.css';
import {store} from './store/';
import { Provider } from "react-redux";
import { BrowserRouter } from 'react-router-dom';
import ConfigRoute from './hoc/ConfigRoute';

ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <ConfigRoute />
      </BrowserRouter>
    </Provider>,
  document.getElementById('root')
);

reportWebVitals();