import update from '../utilities/update';
import {
    AUTH_START,
    AUTH_SUCCESS,
    AUTH_FAIL,
    AUTH_LOGOUT,
} from '../actions/auth/actionTypes';
import {loading,error} from './shared/';

const initialState = {
    token: null,
    error: null,
    loading: false,
};

const authSuccess = (state, action) => {
    return update(state, {
        token: action.token,
        error: null,
        loading: false,
    });
};

const authLogout = (state, action) => {
    return update(state, { token: null });
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_START:
            return loading(state, action);
        case AUTH_SUCCESS:
            return authSuccess(state, action);
        case AUTH_FAIL:
            return error(state, action);
        case AUTH_LOGOUT:
            return authLogout(state, action);
        default:
            return state;
    }
};

export default authReducer;