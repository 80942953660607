export const ADD_MIX_BET='ADD_BET';
export const REMOVE_MIX_BET='REMOVE_BET';

export const SAVE_BET_START='SAVE_BET_START';
export const SAVE_BET_SUCCESS='SAVE_BET_SUCCESS';
export const SAVE_BET_FAIL='SAVE_BET_FAIL';

export const ADD_SINGLE_BET='ADD_SINGLE_BET';
export const REMOVE_SINGLE_BET='REMOVE_SINGLE_BET';

export const SAVE_SINGLE_BET_START='SAVE_SINGLE_BET_START';
export const SAVE_SINGLE_BET_SUCCESS='SAVE_SINGLE_BET_SUCCESS';
export const SAVE_SINGLE_BET_FAIL='SAVE_SINGLE_BET_FAIL';




