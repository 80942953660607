import React from 'react';
import {
      Table, Input,
      Button, DatePicker,
      Checkbox, message, Select
} from 'antd';
import {
      SearchOutlined,
      EyeOutlined
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getHistory, setType } from '../store';

class History extends React.Component {
      state = {
            date: null,
            status: 'ALL',
            searchKey: "",
            searchResult: [],
      }
      onSearchBtnPressed =async () => {
            if (this.state.date) {
                  await this.props.onGet({
                        date: this.state.date,
                        type: this.props.type,
                        status:this.state.status
                  });
            }
            this.props.error && message.error(this.props.error);
      }
      onChangeDate = (_, dateString) => {
            this.setState(value => ({
                  ...value,
                  date: dateString
            }))
      }
      onChangeStatus = (e) => {
            this.setState(value => ({
                  ...value,
                  status: e.target.checked ? 'WIN' : 'ALL'
            }))
      }
      onSearch = ({ target: { value } }) => {
            const result = this.props.bets?.filter(el => `${el.id}`.includes(value));
            this.setState(oldState => ({
                  ...oldState,
                  searchResult: result,
                  searchKey: value
            }))
      }
      columns = [
            {
                  title: 'Bet ID',
                  dataIndex: 'id',
                  key: 'id'
            },
            {
                  title: 'Bet Amount',
                  dataIndex: 'userBetAmount',
                  key: 'userBetAmount'
            },
            {
                  title: 'Win Amount',
                  dataIndex: 'userWinAmount',
                  key: 'userWinAmount'
            },
            {
                  title: 'User Commission',
                  dataIndex: 'userCommissionAmount',
                  key: 'userCommissionAmount'
            },
            {
                  title: 'Paid',
                  dataIndex: 'paid',
                  key: 'paid',
                  render: (text, record) => record.paid ? 'Yes' : 'No'
            },
            {
                  title: 'Action',
                  key: 'action',
                  render: (text, record) => <Link to={`/history/${record.id}/detail`}>
                        <EyeOutlined style={{ fontSize: 18 }} /></Link>
            },
      ]

      render() {
            const { Option } = Select;
            return (
                  <>
                        <div style={styles.container}>
                              <div style={styles.wrapper}>
                                    <div style={styles.top}>
                                          <Select defaultValue={this.props.type}
                                                onChange={this.props.onSetType}>
                                                <Option value="SOCCER">SOCCER</Option>
                                                <Option value="THREED">THREED</Option>
                                                <Option value="FOURD">FOURD</Option>
                                          </Select>
                                          <DatePicker
                                                format="YYYY-MM-DD"
                                                onChange={this.onChangeDate} />
                                          <Checkbox onChange={this.onChangeStatus}>Win Only</Checkbox>
                                          <Button
                                                onClick={this.onSearchBtnPressed}
                                                type="primary"
                                                icon={<SearchOutlined />}>Search</Button>
                                          <Input placeholder="Search"
                                                style={{ width: 200 }}
                                                onChange={this.onSearch}
                                          />
                                    </div>
                                    <Table columns={this.columns} dataSource={this.state.searchKey ?
                                          this.state.searchResult : this.props.bets} />
                              </div>
                        </div>
                  </>
            );
      }
}

const mapStateToProps = state => ({
      loading: state.history.loading,
      error: state.history.error,
      bets: state.history.all,
      type: state.history.type,
})

const mapDispatchToProps = dispatch => ({
      onGet: (obj) => dispatch(getHistory(obj)),
      onSetType: (type) => dispatch(setType(type)),
})

export default connect(mapStateToProps, mapDispatchToProps)(History);

const styles = {
      container: {
            display: 'flex',
            justifyContent: 'center',
            padding: '5px'
      },
      wrapper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: '15px',
            borderRadius: '5px',
            boxShadow: '0px 2px 8px -1px gray',
            width: '75vw',
            minWidth: '500px',
            padding: '15px'
      },
      top: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between'
      }
}