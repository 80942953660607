import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';

export const saveSingleBet = (request) => async dispatch => {
   dispatch({
      type: actionTypes.SAVE_SINGLE_BET_START,
   });
   const options = {
      url: '/bets',
      method: 'POST',
      data: request
   }
   await axios(options)
      .then(response => dispatch({
         type: actionTypes.SAVE_SINGLE_BET_SUCCESS,
         payload: response.data
      }))
      .catch(error => dispatch({
         type: actionTypes.SAVE_SINGLE_BET_FAIL,
         payload: error.response
      }))
}