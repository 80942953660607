export const homeOddPrice = (match) => match.homeUpper ?
    (<span>
        ({match.odds}{match.price >= 0 ?
            `+${match.price}` : match.price})
    </span>) :
    null;

export const awayOddPrice = (match) => match.homeUpper ?
    null :
    (<span>
        ({match.odds}{match.price >= 0 ?
            `+${match.price}` : match.price})
    </span>);