import { Header } from '../components/';
import React from 'react';

export function App(props) {
    return (
        <>
            <Header />
            <div style={{
                paddingLeft: '20px',
                paddingRight: '20px'
            }}>
                {props.children}
            </div>
        </>
    );
}