import axios from 'axios';
import {api} from './api';

axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('555mixPOSToken');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    config.baseURL=api
    return config;
});

export default axios;