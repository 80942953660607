import update from '../utilities/update';
import { loading } from './shared/loading';
import { error } from './shared/error';
import {
   GET_MIX_START,
   GET_MIX_SUCCESS,
   GET_MIX_FAIL
} from '../actions/mix/actionTypes';

const initialState = {
   loading: false,
   error: null,
   message: null,
   all: null
}

const getMixSuccess = (state, action) => {
   return update(state, {
      loading: false,
      all: action.payload
   })
}


const mixReducer = (state = initialState, action) => {
   switch (action.type) {
      case GET_MIX_START:
         return loading(state, action);
      case GET_MIX_SUCCESS:
         return getMixSuccess(state, action);
      case GET_MIX_FAIL:
         return error(state, action);
      default:
         return state;
   }
}

export default mixReducer;