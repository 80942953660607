import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import ReduxThunk from 'redux-thunk';
import activeBetReducer from "./reducers/activeBetReducer";
import authReducer from './reducers/authReducer';
import betReducer from "./reducers/betReducer";
import { fourDReducer } from "./reducers/fourDReducer";
import historyReducer from "./reducers/historyReducer";
import mixReducer from "./reducers/mixReducer";
import singleReducer from "./reducers/singleReducer";
import { threeDReducer } from "./reducers/threeDReducer";
import userReducer from "./reducers/userReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  mix:mixReducer,
  single:singleReducer,
  bet:betReducer,
  activeBet:activeBetReducer,
  history:historyReducer,
  fourD:fourDReducer,
  threeD:threeDReducer,
});

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(ReduxThunk))
);
