import React from 'react';
import { Table, Button } from 'antd';
import {
    PrinterFilled,
    ArrowLeftOutlined,
} from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { ComponentToPrint } from './ComponentToPrint';

const homeOddPrice = (match) => match.homeUpper ?
    (<span>
        ({match.odds}{match.price >= 0 ?
            `+${match.price}` : match.price})
    </span>) :
    null;
const awayOddPrice = (match) => match.homeUpper ?
    null :
    (<span>
        ({match.odds}{match.price >= 0 ?
            `+${match.price}` : match.price})
    </span>);

const columns = [
    {
        title: 'Home',
        key: 'home',
        render: (text, record) => {
            return <>
                {`${record.match.home.name}`}
                {homeOddPrice(record)}
            </>
        }
    },
    {
        title: 'Goal Score',
        key: 'goalScore',
        render: (text, record) => `(${record.match.homeScore}-${record.match.awayScore})`
    },
    {
        title: 'Away',
        key: 'away',
        render: (text, record) => {
            return <>
                {`${record.match.away.name}`}
                {awayOddPrice(record)}
            </>
        }
    },
    {
        title: 'Goal Total Price',
        key: 'goalTotalPrice',
        render: (text, record) => record.betType === 'BODY' ? '-' :
            `(${record.odds}${record.price >= 0 ?
               `+${record.price}` : record.price})`
    },
    {
        title: 'Bet Choice',
        key: 'betChoice',
        render: (text, record) => getChoice(record)
    }
]

const getChoice = (bet) => {
    if (bet.betType === 'BODY' && bet.betChoice === 'LOWER') {
        const team = bet.homeUpper ? 'away' : 'home';
        return bet.match[team].name;
    }
    if (bet.betType === 'BODY' && bet.betChoice === 'UPPER') {
        const team = bet.homeUpper ? 'home' : 'away';
        return bet.match[team].name;
    }
    return bet.betChoice;
}

class BetDetail extends React.Component {
    render() {
        const { bet_id } = this.props.match?.params;
        const filteredBet = this.props.activeBets.filter(el => `${el.id}` === bet_id);
        const bet = filteredBet.length > 0 ? filteredBet[0] : null;
        return (
            <>
                <div style={styles.container}>
                    <div style={styles.buttonWrapper}>
                        <Button
                            style={{ marginRight: 'auto' }}
                            icon={<ArrowLeftOutlined />}
                            type="primary"
                            onClick={() => this.props.history.goBack()}
                        >
                            Back
                </Button>
                    </div>
                    <br />
                    <div style={styles.wrapper}>
                        <div style={styles.top}>
                            <span>
                                Bet : {`${bet_id}`}
                            </span>
                            <span>
                                Bet : {bet?.userBetAmount}
                            </span>
                            <span>
                                Commission : {bet?.userCommissionAmount}
                            </span>
                            <span>
                                Win : {bet?.userWinAmount}
                            </span>
                            <ReactToPrint
                                trigger={() => <Button
                                    icon={<PrinterFilled />}
                                    style={{borderColor:'blue', color: 'blue' }}>
                                    PRINT
                                </Button>}
                                content={() => this.componentRef}
                            />
                            <div style={{ display: 'none' }}><ComponentToPrint
                                ref={el => (this.componentRef = el)}
                                bet={bet}
                                user={this.props.user}
                            /></div>
                        </div>
                        <Table
                            columns={columns}
                            dataSource={bet?.betMatches}
                        />
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    activeBets: state.activeBet.all,
    user:state.user.current
})

export default withRouter(connect(mapStateToProps, null)(BetDetail));

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '5px'
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: '15px',
        borderRadius: '5px',
        boxShadow: '0px 2px 8px -1px gray',
        width: '75vw',
        minWidth: '500px',
        padding: '15px',
        boxSizing: 'border-box'
    },
    top: {
        display: 'flex',
        gap: '60px',
        fontSize: '16px'
    },
    buttonWrapper: {
        width: '75vw',
        minWidth: '500px',
    }
}