import update from '../utilities/update';
import { loading } from './shared/loading';
import { error } from './shared/error';
import {
      GET_ACTIVE_BETS_START,
      GET_ACTIVE_BETS_SUCCESS,
      GET_ACTIVE_BETS_FAIL,
      GET_34_ACTIVE_BETS_START,
      GET_34_ACTIVE_BETS_SUCCESS,
      GET_34_ACTIVE_BETS_FAIL,
} from '../actions/activeBet/actionTypes';

const initialState = {
      loading: false,
      message: null,
      error: null,
      all: [],
      twoThreeFour: [],
}

const getActiveBets = (state, action) => {
      return update(state, {
            loading: false,
            all: action.payload
      })
}

const get234ActiveBets = (state, action) => {
      return update(state, {
            loading: false,
            twoThreeFour: action.payload,
      })
}

const get234ActiveBetsFail = (state, action) => {
      return update(state, {
            loading: false,
            error: action.payload
      })
}

const activeBetReducer = (state = initialState, action) => {
      switch (action.type) {
            case GET_ACTIVE_BETS_START:
                  return loading(state, action);
            case GET_ACTIVE_BETS_SUCCESS:
                  return getActiveBets(state, action);
            case GET_ACTIVE_BETS_FAIL:
                  return error(state, action);
            case GET_34_ACTIVE_BETS_START:
                  return loading(state, action);
            case GET_34_ACTIVE_BETS_SUCCESS:
                  return get234ActiveBets(state, action);
            case GET_34_ACTIVE_BETS_FAIL:
                  return get234ActiveBetsFail(state, action);
            default:
                  return state;
      }
}

export default activeBetReducer;