import update from '../utilities/update';
import { loading } from './shared/loading';
import { error } from './shared/error';
import {
      GET_HISTORY_START,
      GET_HISTORY_SUCCESS,
      GET_HISTORY_FAIL,
      PAID_BET_START,
      PAID_BET_SUCCESS,
      PAID_BET_FAIL,
      SET_TYPE,
} from '../actions/history/actionTypes';

const initialState = {
      loading: false,
      error: null,
      message: null,
      all: [],
      type: 'SOCCER',
}

const getHistory = (state, action) => {
      return update(state, {
            loading: false,
            all: action.payload
      })
}

const paidBet = (state, { payload }) => {
      return update(state, {
            loading: false,
            all: state.all.map(el => el?.id === payload.id ? payload : el)
      })
}

const setType = (state, { payload }) => {
      return update(state, {
            type: payload
      })
}

const historyReducer = (state = initialState, action) => {
      switch (action.type) {
            case GET_HISTORY_START:
                  return loading(state, action);
            case GET_HISTORY_SUCCESS:
                  return getHistory(state, action);
            case GET_HISTORY_FAIL:
                  return error(state, action);
            case PAID_BET_START:
                  return loading(state, action);
            case PAID_BET_SUCCESS:
                  return paidBet(state, action);
            case PAID_BET_FAIL:
                  return error(state, action);
            case SET_TYPE:
                  return setType(state, action);
            default:
                  return state;
      }
}

export default historyReducer;