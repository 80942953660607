import update from '../utilities/update';
import { loading } from './shared/loading';
import {
   ADD_MIX_BET,
   REMOVE_MIX_BET,
   SAVE_BET_START,
   SAVE_BET_SUCCESS,
   SAVE_BET_FAIL,
   ADD_SINGLE_BET,
   REMOVE_SINGLE_BET,
   SAVE_SINGLE_BET_START,
   SAVE_SINGLE_BET_SUCCESS,
   SAVE_SINGLE_BET_FAIL,
} from '../actions/bet/actionTypes';

const initialState = {
   loading: false,
   error: null,
   message: null,
   mixBets: [],
   savedBet: null,
   singleBets: [],
   priceChangedBets: [],
}

const checkIn = (array, obj) => {
   return array.filter(el => el.matchId === obj.matchId).length > 0;
}

const addMixBet = (state, action) => {
   return update(state, {
      mixBets: checkIn(state.mixBets, action.payload) ?
         state.mixBets : [...state.mixBets, action.payload]
   });
}

const removeMixBet = (state, action) => {
   return update(state, {
      mixBets: state.mixBets.filter(el => el.matchId !== action.payload.matchId)
   })
}

const saveBets = (state, action) => {
   return update(state, {
      mixBets: [],
      loading: false,
      savedBet: action.payload,
      message: 'Saved successfully',
      priceChangedBets: []
   })
}

const addSingleBet = (state, action) => {
   return update(state, {
      singleBets: state.singleBets.length === 0 ?
         [action.payload] : state.singleBets
   })
}

const removeSingleBet = (state, action) => {
   return update(state, {
      singleBets: state.singleBets.filter(el => el.matchId !== action.payload.matchId)
   })
}

const saveSingleBet = (state, action) => {
   return update(state, {
      singleBets: [],
      loading: false,
      message: 'Saved successfully',
      savedBet: action.payload,
      priceChangedBets: []
   })
}

const mixBetFail = (state, action) => {
   let betList = [];
   if (action.payload?.data?.betMatches) {
      betList = state.mixBets.map(function (bet) {
         let result = action.payload?.data?.betMatches?.find(value => value.matchId === bet.matchId)
         return result ? result : bet;
      })
   }
   return update(state, {
      loading: false,
      error: action.payload?.data?.message || action.payload?.status,
      mixBets: betList.length ? betList : state.mixBets,
      priceChangedBets: action?.payload?.data?.betMatches || []
   })
}

const singleBetFail = (state, action) => {
   let betList = [];
   if (action.payload?.data?.betMatches) {
      betList = state.singleBets.map(function (bet) {
         let result = action.payload?.data?.betMatches?.find(value => value.matchId === bet.matchId)
         return result ? result : bet;
      })
   }
   return update(state, {
      loading: false,
      error: action.payload?.data?.message || action.payload?.status,
      singleBets: betList.length ? betList : state.singleBets,
      priceChangedBets: action?.payload?.data?.betMatches || []
   })
}

const betReducer = (state = initialState, action) => {
   switch (action.type) {
      case ADD_MIX_BET:
         return addMixBet(state, action);
      case REMOVE_MIX_BET:
         return removeMixBet(state, action);
      case SAVE_BET_START:
         return loading(state, action);
      case SAVE_BET_SUCCESS:
         return saveBets(state, action);
      case SAVE_BET_FAIL:
         return mixBetFail(state, action);
      case ADD_SINGLE_BET:
         return addSingleBet(state, action);
      case REMOVE_SINGLE_BET:
         return removeSingleBet(state, action);
      case SAVE_SINGLE_BET_START:
         return loading(state, action);
      case SAVE_SINGLE_BET_SUCCESS:
         return saveSingleBet(state, action);
      case SAVE_SINGLE_BET_FAIL:
         return singleBetFail(state, action);
      default:
         return state;
   }
}

export default betReducer;