import React from 'react';
import { Table, Button, Input, InputNumber, message, Form } from 'antd';
import './Single.css';
import {
   getSingle,
   addSingleBet,
   removeSingleBet,
   saveSingleBet
} from '../store';
import { connect } from 'react-redux';
import {
   PrinterFilled,
   UpOutlined,
   DownOutlined,
   DeleteOutlined,
   SaveOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { homeOddPrice, awayOddPrice } from './oddPrice';
import { SingleBetToPrint } from './SingleBetToPrint';

class Mix extends React.Component {
   state = {
      validateStatus: null,
      amount: 0,
      errorMsg: null,
   }
   componentDidMount() {
      this.props.onGetMatches();
      this.inputRef = React.createRef();
   }
   componentDidUpdate() {
      if (this.props.error) message.error(this.props.error);
   }
   bodyChoice = (match, choice) => {
      const bet = {
         matchId: match?.id,
         betType: 'BODY',
         betChoice: choice,
         homeUpper: match?.homeUpper,
         odds: match?.odds,
         price: match?.price,
         goalTotal: match?.goalTotal,
         goalTotalPrice: match?.goalTotalPrice,
         highTax: match?.highTax
      }
      this.props.onAddBet(bet);
   }
   goalChoice = (match, choice) => {
      const bet = {
         matchId: match?.id,
         betType: 'GOALS',
         betChoice: choice,
         homeUpper: match?.homeUpper,
         odds: match?.odds,
         price: match?.price,
         goalTotal: match?.goalTotal,
         goalTotalPrice: match?.goalTotalPrice
      }
      this.props.onAddBet(bet);
   }
   getTeamName = (bet, team) => {
      const match = this.props.matches?.filter(el => el.id === bet.matchId);
      return match?.length > 0 ? match[0][`${team}`].name : 'Not found the team';
   }

   getPrice = (bet) => {
      return bet.betType === 'BODY' ? (<div style={{ width: '50px' }}>
         ({bet.odds}{bet.price >= 0 ?
            `+${bet.price}` : bet.price})
      </div>) :
         (<div style={{ width: '50px' }}>
            ({bet.goalTotal}{bet.goalTotalPrice >= 0 ?
               `+${bet.goalTotalPrice}` :
               bet.goalTotalPrice})
         </div>)
   }
   getChoice = (bet) => {
      if (bet.betType === 'BODY' && bet.betChoice === 'HOME') {
         return this.getTeamName(bet, 'home')
      }
      if (bet.betType === 'BODY' && bet.betChoice === 'AWAY') {
         return this.getTeamName(bet, 'away')
      }
      return bet.betChoice === 'UPPER' ? 'ဂိုးပေါ်' : 'ဂိုးအောက်';
   }
   bodySearch = (value) => {
      let team;
      const result = this.props.matches.filter(el => {
         if (el?.homeNo === value) {
            team = 'home';
            return true;
         }
         if (el?.awayNo === value) {
            team = 'away';
            return true
         }
         return false;
      });
      if (result.length > 0) {
         const match = result[0];
         if (team === 'home') {
            this.bodyChoice(match, 'HOME');
         }
         if (team === 'away') {
            this.bodyChoice(match, 'AWAY');
         }
      }
   }

   goalSearch = (value, sign) => {
      const result = this.props.matches.filter(el => el?.homeNo === value);
      if (result.length > 0) {
         const match = result[0];
         this.goalChoice(match, sign === '+' ? 'UPPER' : 'LOWER');
      }
   }

   handleSearch = ({ target: { value } }) => {
      let pattern = /[+-]$/
      const isNumber = (num) => +num == num;
      if (pattern.test(value)) {
         let length = value.length;
         let lastIndexForSign = length - 1;
         let startForSubStr = 0;
         let lengthForSubStr = length - 1;
         let sign = value[lastIndexForSign];
         let string = value
            .substr(startForSubStr, lengthForSubStr);
         //SubString(includeStart,excludeEnd)
         //,SubStr(start,length)
         if (isNumber(string)) {
            this.goalSearch(+string, sign);
            this.inputRef.current.state.value = "";
         } else {
            message.warning("Not a number")
         }
      } else {
         if (isNumber(value)) {
            this.bodySearch(+value);
            this.inputRef.current.state.value = "";
         } else {
            message.warning("Not a number")
         }
      }
   }

   printer = async (print) => {
      await this.onSave();
      if (this.props.betMessage) {
         print();
      }
   }

   onSave = async () => {
      if (this.state.amount >= 500 && this.props.bets?.length === 1) {
         const request = {
            collectionId: this.props.collectionId,
            betMatches: this.props.bets,
            betAmount: this.state.amount
         }
         await this.props.onSave(request);
         if (this.props.betMessage) {
            message.success(this.props.betMessage);
         }
         if (this.props.betError) {
            message.warning(this.props.betError);
         }
      } else {
         let error = '';
         if (this.state.amount < 500) error = error + "Minimum 500Ks is Required. ";
         if (this.props.bets?.length === 0) error = error + "Select a match."
         message.warning(error);
      }
   }

   onEnterAmount = (v) => {
      if (v < 500) {
         this.setState(value => ({
            ...value,
            validateStatus: 'error',
            errorMsg: 'Minimum 500 Ks'
         }))
      } else {
         this.setState(value => ({
            ...value,
            validateStatus: 'success',
            errorMsg: null,
            amount: v
         }))
      }
   }
   getMaxPayout = ({ amount, highTax }) => {
      let maxPayout = 0;
      let winCommission = 0;
      if (highTax) {
         winCommission = this.props.user?.singleBetCommission?.highWinCommission;

      } else {
         winCommission = this.props.user?.singleBetCommission?.winCommission;
      }
      let total = amount - (amount * winCommission / 100);
      maxPayout = total + amount;
      return maxPayout;
   }
   render() {
      const matchColumns = [
         {
            title: 'Time',
            dataIndex: 'startTime',
            key: 'startTime',
            render: (text, record) => moment(record.startTime).format('hh:mm a')
         },
         {
            title: 'Home',
            dataIndex: 'home',
            key: 'home',
            render: (text, record) => {
               return (
                  <span
                     className="on-hover"
                     style={styles.pointer}
                     onClick={() => {
                        this.bodyChoice(record, 'HOME')
                     }}>
                     {`${record.homeNo}.${record.home.name}`}
                     {homeOddPrice(record)}
                  </span>
               );
            }
         },
         {
            title: 'Over',
            key: 'over',
            render: (text, record) => <UpOutlined
               onClick={() => this.goalChoice(record, 'UPPER')}
               style={styles.border} />
         },
         {
            title: 'Goals Price',
            key: 'goalsPrice',
            render: (text, record) => (
               <>
                  ({record.goalTotal}{record.goalTotalPrice >= 0 ?
                     `+${record.goalTotalPrice}` :
                     record.goalTotalPrice})
               </>
            )
         },
         {
            title: 'Under',
            key: 'under',
            render: (text, record) => <DownOutlined
               onClick={() => this.goalChoice(record, 'LOWER')}
               style={styles.border} />
         },
         {
            title: 'Away',
            dataIndex: 'away',
            key: 'away',
            render: (text, record) => {
               return (
                  <span
                     className="on-hover"
                     style={styles.pointer}
                     onClick={() => this.bodyChoice(record, 'AWAY')}>
                     {`${record.awayNo}.${record.away.name}`}
                     {awayOddPrice(record)}
                  </span>
               );
            }
         },
      ];
      const cartColumn = [
         {
            title: 'Match',
            dataIndex: 'match',
            key: 'match',
            render: (text, record) => this.getTeamName(record, 'home')
         },
         {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (text, record) => this.getPrice(record)
         },
         {
            title: 'Choice',
            dataIndex: 'choice',
            key: 'choice',
            render: (text, record) => this.getChoice(record)
         },
         {
            title: 'Action',
            key: 'action',
            render: (text, record) => <DeleteOutlined
               onClick={() => this.props.onRemoveBet(record)}
               style={{ color: 'red' }} />
         }
      ]
      return (
         <>
            <div style={{
               display: 'flex',
            }}>
               <Table
                  style={{
                     width: '65%',
                     height: '80vh',
                     overflowY: 'scroll',
                     scrollbarWidth: 'thin'
                  }}
                  columns={matchColumns}
                  dataSource={this.props.matches}
                  pagination={false}
                  loading={this.props.loading}
                  rowClassName={(record) => record.highTax ? "yellow" : ""}
               />
               <div style={{
                  width: '35%',
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '10px'
               }}>
                  <Input
                     ref={this.inputRef}
                     onPressEnter={this.handleSearch}
                     style={{
                        width: '150px'
                     }} />
                  <br />
                  <div style={{
                     border: '1px solid #E0E0E0',
                     borderRadius: '5px',
                  }}>
                     <Table
                        style={{
                           height: '300px',
                           overflowY: 'scroll'
                        }}
                        columns={cartColumn}
                        dataSource={this.props.bets}
                        pagination={false}
                        rowClassName={(record) =>
                           this.props.priceChangedBets?.some(
                              value => value.matchId === record.matchId) ? "priceChanged" : ""
                        }
                     />
                  </div>
                  <br />
                  <div style={{
                     display: 'flex',
                     justifyContent: 'flex-end'
                  }}>
                     <Form
                        name="save_bet">
                        <Form.Item
                           name="amount"
                           help={this.state.errorMsg}
                           validateStatus={this.state.validateStatus}>
                           <InputNumber
                              onChange={this.onEnterAmount}
                              placeholder="Amount"
                              value={this.state.amount}
                              style={{
                                 width: '200px',
                                 marginLeft: 'auto'
                              }} />
                        </Form.Item>
                     </Form>
                  </div>
                  <br />
                  <br />
                  <div style={{
                     color: 'rbga(0,0,0,0.82)',
                     fontSize: '18px',
                     width: '100%',
                     textAlign: 'center'
                  }}>
                     Max Payout : {
                        this.props.bets?.length > 0 ?
                           this.getMaxPayout({
                              amount: this.state.amount,
                              highTax: this.props.bets[0]?.highTax
                           }) : 0} Ks
                  </div>
                  <br />
                  <br />
                  <div style={{
                     width: '100%',
                     display: 'flex',
                     justifyContent: 'space-between'
                  }}>
                     <Button
                        loading={this.props.betLoading}
                        onClick={this.onSave}
                        style={styles.button}
                        icon={<SaveOutlined />}>
                        Save
                     </Button>
                     {
                        (this.props.bets?.length > 0 &&
                           this.state.amount >= 500) ?
                           <ReactToPrint content={() => this.componentRef} >
                              <PrintContextConsumer>
                                 {({ handlePrint }) => {
                                    const that = this;
                                    return <Button onClick={() => { that.printer(handlePrint) }}
                                       loading={this.props.betLoading}
                                       icon={<PrinterFilled />}
                                       style={styles.button}>
                                       SAVE AND PRINT
                                    </Button>
                                 }}
                              </PrintContextConsumer></ReactToPrint> :
                           <Button
                              onClick={this.onSave}
                              icon={<PrinterFilled />}
                              style={styles.button}>
                              SAVE AND PRINT
                           </Button>
                     }

                     <div style={{ display: 'none' }}><SingleBetToPrint
                        ref={el => (this.componentRef = el)}
                        bet={this.props.bet}
                        getMaxPayout={({ amount, highTax }) => this.getMaxPayout({ amount, highTax })}
                     /></div>
                  </div>
               </div>
            </div>
         </>
      );
   }
}

const mapStateToProps = state => ({
   loading: state.single.loading,
   error: state.single.error,
   collectionId: state.single.all?.id,
   matches: state.single.all?.matches,
   priceChangedBets: state.bet.priceChangedBets,
   bets: state.bet.singleBets,
   betLoading: state.bet.loading,
   betMessage: state.bet.message,
   betError: state.bet.error,
   bet: state.bet.savedBet,
   user: state.user.current,
})

const mapDispatchToProps = dispatch => ({
   onGetMatches: () => dispatch(getSingle()),
   onAddBet: (bet) => dispatch(addSingleBet(bet)),
   onRemoveBet: (bet) => dispatch(removeSingleBet(bet)),
   onSave: (request) => dispatch(saveSingleBet(request))
})

export default connect(mapStateToProps, mapDispatchToProps)(Mix);

const styles = {
   button: {
      color: '#3F51B5',
      borderColor: '#3F51B5',
      textTransform: 'uppercase',
      minWidth: '100px'
   },
   printBtn: {
      color: "green",
      borderColor: "green",
      minWidth: '100px'
   },
   border: {
      border: '1px solid blue',
      color: 'blue',
      borderRadius: '5px',
      padding: '5px 10px'
   },
   pointer: {
      cursor: 'pointer',
   }
}