import update from '../utilities/update';
import { loading } from './shared/loading';
import { error } from './shared/error';
import {
      FOURD_COLLECTION_REQUEST,
      FOURD_COLLECTION_SUCCESS,
      FOURD_COLLECTION_FAIL,
      FOURD_BET_REQUEST,
      FOURD_BET_SUCCESS,
      FOURD_BET_FAIL,
} from '../actions/fourD/actionTypes';

const initialState={
      loading:false,
      betting:false,
      error:null,
      message:null,
      fourDCollection:null,
      fourDNumbers:[],
      savedFourDBet:null,
}

const fourDCollectionSuccess=(state,action)=>{
      return update(state,{
            loading:false,
            fourDCollection:action.payload,
            fourDNumbers:action.payload?.fourDNumbers
      });
}

const fourDBetStart=(state,action)=>{
      return update(state,{
            betting:true,
            error:null,
            message:null,
            savedFourDBet:null,
      });
}

const fourDBetSuccess=(state,action)=>{
      return update(state,{
            betting:false,
            message:'4D Bet အောင်မြင်ပါသည်',
            savedFourDBet:action.payload
      })
}

const fourDBetFail=(state,action)=>{
      return update(state,{
            betting:false,
            error:action.payload
      });
}

export const fourDReducer=(state=initialState,action)=>{
      switch(action.type)
      {
            case FOURD_COLLECTION_REQUEST:
                  return loading(state,action);
            case FOURD_COLLECTION_SUCCESS:
                  return fourDCollectionSuccess(state,action);
            case FOURD_COLLECTION_FAIL:
                  return error(state,action);
            case FOURD_BET_REQUEST:
                  return fourDBetStart(state,action);
            case FOURD_BET_SUCCESS:
                  return fourDBetSuccess(state,action);
            case FOURD_BET_FAIL:
                  return fourDBetFail(state,action);
            default:
                  return state;
      }
}