import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';
import { getCurrentUser } from '../user/getCurrentUser';

export const betThreeD = ({
      collectionId,
      betThreeDNumbers
}) => async dispatch => {

      dispatch({
            type: actionTypes.THREED_BET_REQUEST,
      });

      const options = {
            method: 'POST',
            url: '/threeDBets',
            data: {
                  collectionId,
                  betThreeDNumbers
            }
      }

      await axios(options)
            .then(response => {
                  dispatch({
                        type: actionTypes.THREED_BET_SUCCESS,
                        payload: response.data
                  });
                  dispatch(getCurrentUser());
            })
            .catch(error => {
                  dispatch({
                        type: actionTypes.THREED_BET_FAIL,
                        payload: getMessage(error)
                  })
            });
}