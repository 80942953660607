import React from 'react';
import { Tabs } from 'antd';
import {
      BarsOutlined,
      HistoryOutlined,
      ControlOutlined,
      InsertRowBelowOutlined
} from '@ant-design/icons';
import { ReceiptSetting, BetList, Single, History, BetDetail, Mix, HistoryDetail } from '../components';
import Icon from '@ant-design/icons';
import { ReactComponent as ballIcon } from '../icons/ball.svg';
import { ReactComponent as fuzballIcon } from '../icons/fuzball.svg';
import { ReactComponent as threeD } from '../icons/threeD.svg';
import { getFourDCollection } from '../store/actions/fourD/getFourDCollection';
import { getThreeDCollection } from '../store/actions/threeD/getThreeDCollection';
import { ReactComponent as fourD } from '../icons/fourD.svg';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCurrentUser, getMix, getSingle, getActiveBets, get34ActiveBets } from '../store';
import { FourD } from '../components/FourD';
import { ThreeD } from '../components/ThreeD';
import NumberBetList from '../components/NumberBetList';
import NumberBetDetail from '../components/NumberBetDetail';

class TabContainer extends React.Component {
      componentDidMount() {
            this.props.onGetCurrentUser();
      }
      render() {
            const { TabPane } = Tabs;
            const { match, location, history } = this.props;
            const path = location?.pathname;
            let activeKey = path?.includes("currentBet") ?
                  "/currentBet" : path?.includes("current34Bet")
                        ? "/current34Bet" : path;
            return (
                  <>
                        <Tabs
                              defaultActiveKey={activeKey}
                              onChange={key => {
                                    if (key === "/mixBet") this.props.onGetMixMatches();
                                    if (key === "/singleBet") this.props.onGetSingleMatches();
                                    if (key === "/currentBet") this.props.onGetActive();
                                    if (key === "/current34Bet") this.props.onGet34Active();
                                    if (key === "/fourD") this.props.onGet4DCollection();
                                    if (key === "/threeD") this.props.onGet3DCollection();
                                    history.push(key);
                              }}
                        >
                              <TabPane
                                    tab={
                                          <span>
                                                <Icon component={fuzballIcon} />
                                                Mix
                                          </span>
                                    }
                                    key="/mixBet"
                              >
                                    <Mix />
                              </TabPane>
                              <TabPane
                                    tab={
                                          <span>
                                                <Icon component={ballIcon} />
                                                Single
                                          </span>
                                    }
                                    key="/singleBet"
                              >
                                    <Single />
                              </TabPane>
                              <TabPane
                                    tab={
                                          <span>
                                                <Icon component={threeD} />
                                                Three D
                                          </span>
                                    }
                                    key="/threeD">
                                    <ThreeD />
                              </TabPane>
                              <TabPane
                                    tab={
                                          <span>
                                                <Icon component={fourD} />
                                                Four D
                                          </span>
                                    }
                                    key="/fourD">
                                    <FourD />
                              </TabPane>
                              
                              <TabPane
                                    tab={
                                          <span>
                                                <BarsOutlined />
                                                Bet List
                                          </span>
                                    }
                                    key="/currentBet"
                              >
                                    {match?.params?.bet_id ? <BetDetail /> : <BetList />}
                              </TabPane>
                              <TabPane
                                    tab={
                                          <span>
                                                <InsertRowBelowOutlined />
                                                3/4 Bet List
                                          </span>
                                    }
                                    key="/current34Bet"
                              >
                                    {match?.params?.bet_id ? <NumberBetDetail /> : <NumberBetList />}
                              </TabPane>
                              <TabPane
                                    tab={
                                          <span>
                                                <HistoryOutlined />
                                                History
                                          </span>
                                    }
                                    key="/history"
                              >
                                    {match?.params?.history_id ? <HistoryDetail /> : <History />}
                              </TabPane>
                              <TabPane
                                    tab={
                                          <span>
                                                <ControlOutlined />
                                                Setting
                                          </span>
                                    }
                                    key="/setting"
                              >
                                    <ReceiptSetting />
                              </TabPane>
                        </Tabs>
                  </>
            );
      }
}

const mapDispatchToProps = dispatch => ({
      onGetCurrentUser: () => dispatch(getCurrentUser()),
      onGetMixMatches: () => dispatch(getMix()),
      onGetSingleMatches: () => dispatch(getSingle()),
      onGetActive: () => dispatch(getActiveBets()),
      onGet34Active: () => dispatch(get34ActiveBets()),
      onGet4DCollection: () => dispatch(getFourDCollection()),
      onGet3DCollection: () => dispatch(getThreeDCollection()),
})

export default withRouter(connect(null, mapDispatchToProps)(TabContainer));