import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

export const getActiveBets=()=>async dispatch=>{
    dispatch({
        type:actionTypes.GET_ACTIVE_BETS_START
    });
    axios.get('/bets')
         .then(response=>dispatch({
             type:actionTypes.GET_ACTIVE_BETS_SUCCESS,
             payload:response.data
         }))
         .catch(err=>dispatch({
             type:actionTypes.GET_ACTIVE_BETS_FAIL,
             payload:getMessage(err)
         }))
}