import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

export const paidBet=({paid,id})=>async dispatch=>{
    dispatch({
        type:actionTypes.PAID_BET_START
    });
    const options={
        url:`/bets/${id}/paid`,
        method:'PUT',
        data:{
            paid
        }
    }
    await axios(options)
                .then(response=>dispatch({
                    type:actionTypes.PAID_BET_SUCCESS,
                    payload:response.data
                }))
                .catch(error=>dispatch({
                    type:actionTypes.PAID_BET_FAIL,
                    payload:getMessage(error)
                }))
}