import React, { useEffect, useState } from 'react';
import { Input, Button } from 'antd';

export function ReceiptSetting() {
    const [header, setHeader] = useState("");
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const storedText = localStorage.getItem("555mixPOSReceipt");
        if (storedText) setHeader(storedText);
    }, []);
    const onChange = (e) => {
        setHeader(e.target.value);
    }
    const saveHandler = () => {
        setLoading(true);
        localStorage.setItem("555mixPOSReceipt", header);
        setTimeout(() => setLoading(false), 300);
    }
    return (
        <>
            <div style={styles.container}>
                <span style={styles.heading}>
                    Receipt Setting
                </span>
                <br />
                <br />
                <Input value={header} onChange={onChange} />
                <br />
                <br />
                <Button
                    loading={loading}
                    type="primary"
                    onClick={saveHandler}
                >
                    Save
                </Button>
            </div>
        </>
    );
}

const styles = {
    container: {
        boxSizing: 'border-box',
        width: '400px',
        padding: '20px',
        margin: '20px',
        borderRadius: '5px',
        boxShadow: '0px 2px 10px -4px #000000'
    },
    heading: {
        fontSize: '25px',
        color: '#666666',
        fontWeight: '600'
    }
}