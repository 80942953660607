import {
      Row, Col, InputNumber,
      Space, Table, message as antdMessage, Input, Button,
      Alert, Spin
} from 'antd';
import {
      SaveOutlined,
      PrinterFilled,
      DeleteOutlined
} from '@ant-design/icons';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFourDCollection } from '../store/actions/fourD/getFourDCollection';
import { betFourD } from '../store/actions/fourD/betFourD';
import { useReactToPrint } from 'react-to-print';
import { FourDBetToPrint } from './FourDBetToPrint';


const getRange = (number) => {
      let start = 0;
      let range = 1000;
      while (true) {
            if (number < range) {
                  break;
            }
            else {
                  range += 1000;
                  start = range - 1000;
            }
      }
      return [start, range];
}

export function FourD() {

      const dispatch = useDispatch();

      const { loading,
            fourDNumbers,
            betting,
            message,
            error,
            fourDCollection,
            savedFourDBet } = useSelector(state => state.fourD);

      useEffect(() => {
            dispatch(getFourDCollection());
      }, []);

      let [data, setData] = useState([]);

      const [number, setNumber] = useState(null);

      const amountRef = useRef();
      const numberRef = useRef();

      const [amountInput, setAmountInput] = useState(null);

      const onChange = (e) => {
            const { value } = e.target;
            if (+value !== 0 && !+value) {
                  antdMessage.warn("နံပါတ်မှားနေသည်");
                  return;
            }
            setNumber(value);
            if (value.length === 4) {
                  amountRef.current.focus();
            }
      }

      const onEnterNumber = () => amountRef.current.focus();

      const onAdd = (e) => {
            const value = +e.target.value;
            if (value !== 0 && !value) {
                  antdMessage.warn("နံပါတ်မှားနေသည်");
                  return;
            }
            if (value < 100) {
                  antdMessage.warn("ထိုးငွေမလုံလောက်ပါ");
                  return;
            }

            // to slice 10000 into 10 parts for performance reason
            const [start, end] = getRange(+number);
            if (fourDNumbers.length > 0) {

                  const slicedRange = fourDNumbers.slice(start, end);

                  if (slicedRange?.length > 0) {
                        const filteredNumber = slicedRange.filter(
                              fourD => fourD.number === (number + ""));

                        let result = filteredNumber[0];

                        if (filteredNumber.length > 0) {
                              if (+result?.status === 3) {
                                    antdMessage.warn("ပိတ်ဂဏန်းမရပါ");
                                    return;
                              }
                              const isExisted = data.some(obj => obj.fourDNumberId === result.id);
                              if (isExisted) {
                                    let newData = data.map((obj) => {
                                          if (obj.fourDNumberId === result.id) {
                                                return {
                                                      ...obj,
                                                      amount: obj.amount + value
                                                }
                                          }
                                          return obj;
                                    });
                                    setData(newData);
                              }
                              else {
                                    setData(oldData => [...oldData,
                                    {
                                          fourDNumberId: result.id,
                                          number: result.number,
                                          amount: value,
                                    }])
                              }
                        }
                  }
            }
            setNumber(null);
            setAmountInput(null);
            numberRef.current.focus();
      }

      const onSave = async () => {
            if (data.length === 0) {
                  antdMessage.warn("အကွက်ရွေးပါ");
                  return;
            }
            await dispatch(betFourD({
                  collectionId: fourDCollection?.id,
                  betFourDNumbers: data
            }));
            setData([]);
            setNumber(null);
            setAmountInput(null);
            numberRef.current.focus();
      }

      const componentRef = useRef();

      const handlePrint = useReactToPrint({
            content: () => componentRef.current,
      });

      const onSaveAndPrint = async () => {
            if (data.length === 0) {
                  antdMessage.warn("အကွက်ရွေးပါ");
                  return;
            }
            await dispatch(betFourD({
                  collectionId: fourDCollection?.id,
                  betFourDNumbers: data
            }));
            setData([]);
            setNumber(null);
            setAmountInput(null);
            handlePrint();
      }

      const onRemove = (number) => {
            setData(oldData => oldData.filter(
                  obj => obj.fourDNumberId !== number.fourDNumberId))
      }

      const columns = [
            {
                  title: 'Number',
                  dataIndex: 'number',
                  key: 'number'
            },
            {
                  title: 'Amount',
                  dataIndex: 'amount',
                  key: 'amount'
            },
            {
                  title: 'Remove',
                  render: (text, record) => <DeleteOutlined onClick={() => onRemove(record)} />,
                  key: 'remove'
            }
      ]

      const showTotal = () => {
            let total = 0;
            data.map(obj => {
                  total += obj.amount;
            })
            return <>{total}</>
      }

      return (
            <>
                  {fourDCollection ? (new Date(fourDCollection.closedTime) > Date.now()
                        ? <><Row>
                              <Col span={8}>
                                    <Row justify='end'>
                                          <Col>
                                                <Space size="large" direction="horizontal">
                                                      <Input
                                                            ref={numberRef}
                                                            value={number}
                                                            placeholder='Number'
                                                            onChange={onChange}
                                                            onPressEnter={onEnterNumber}
                                                            maxLength={4}
                                                            style={{ width: 150 }} />
                                                      <InputNumber placeholder='Amount'
                                                            value={amountInput}
                                                            onChange={value => setAmountInput(value)}
                                                            ref={amountRef}
                                                            style={{ width: 150 }}
                                                            onPressEnter={onAdd} />
                                                </Space>
                                          </Col>
                                    </Row>
                              </Col>
                        </Row>
                              <br />
                              <Row>
                                    <Col span={8}>
                                          <Table
                                                style={styles.table}
                                                bordered
                                                pagination={false}
                                                loading={loading}
                                                rowKey={record => record.number}
                                                dataSource={data}
                                                columns={columns}
                                          />
                                    </Col>
                              </Row>
                              <br />
                              <br />
                              <Row>
                                    <Col span={3}>
                                          <Row justify='space-between'>
                                                <Col>
                                                      Total
                                                </Col>
                                                <Col>
                                                      {showTotal()}
                                                </Col>
                                          </Row>
                                    </Col>
                              </Row>
                              <br />
                              <Row>
                                    <Col span={8}>
                                          {error && <Alert message={error} type="error" />}
                                          {message && <Alert message={message} type="success" />}
                                    </Col>
                              </Row>
                              <br />
                              <Row>
                                    <Col span={8}>
                                          <div style={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'space-between'
                                          }}>
                                                <Button
                                                      loading={betting}
                                                      onClick={onSave}
                                                      style={styles.button}
                                                      icon={<SaveOutlined />}>
                                                      Save
                                                </Button>

                                                <Button
                                                      onClick={onSaveAndPrint}
                                                      icon={<PrinterFilled />}
                                                      style={styles.button}>
                                                      SAVE AND PRINT
                                                </Button>

                                                <div style={{ display: 'none' }}>
                                                      <FourDBetToPrint
                                                            ref={componentRef}
                                                            bet={savedFourDBet} />
                                                </div>
                                          </div>
                                    </Col>
                              </Row></> : <div style={styles.centerText}>ထိုးလို့မရသေးပါ</div>)
                        : <div style={styles.centerText}><Spin /></div>}
            </>
      )
}

const styles = {
      button: {
            color: '#3F51B5',
            borderColor: '#3F51B5',
            textTransform: 'uppercase',
            minWidth: '100px'
      },
      table: {
            height: '300px',
            overflowY: 'auto'
      },
      centerText: {
            width: '100%',
            height: '300px',
            display: 'grid',
            placeItems: 'center',
            textAlign: 'center'
      }
}