import React from 'react';
import moment from 'moment';

export class ThreeDBetToPrint extends React.Component {
   render() {
      const bet = this.props.bet;
      let title = localStorage.getItem("555mixPOSReceipt");
      let date = moment(bet?.betTime).format('YYYY/MM/DD');
      let time = moment(bet?.betTime).format('hh:mma');
      let total = bet?.userBetAmount;
      let betNumbers = bet?.betThreeDNumbers?.map(num =>
      (<div style={styles.betMatch}>
         <div style={styles.textLeft}>
               <span>
                  {num?.threeDNumber?.number}
               </span>
         </div>
         <div style={styles.textCenter}>
            <span>
                  {num?.threeDNumber?.winPercent}
            </span>
         </div>
         <div style={styles.textRight}>
            <span>{num?.userBetAmount}</span>
         </div>
      </div>)
      );
      
      return (
         <>
            <div style={styles.container}>
               <h1 style={styles.brand}>
                  {title}
               </h1>
               <div style={styles.basicInfo}>
                  <span>Bet ID : {bet?.id}</span>
                  <div style={styles.dateTime}>
                     <span>ရက်စွဲ:{date}</span>
                     <span>အချိန်:{time}</span>
                  </div>
               </div>
               <div style={styles.dottedHr} />
               <div style={styles.tbh}>
                  <span style={styles.textLeft}>နံပါတ်</span>
                  <span style={styles.textCenter}>အဆ</span>
                  <span style={styles.textRight}>ထိုးငွေ</span>
               </div>
               <div style={styles.dottedHr} />
               {betNumbers}
               <div style={styles.dottedHr} />
               <div style={styles.result}>
                  <div style={styles.label}>
                     <span>စုစုပေါင်း</span><br />
                  </div>
                  <div style={styles.amounts}>
                     <span>{total}</span>
                  </div>
               </div>
               <div style={styles.dottedHr} />
            </div>
         </>
      );
   }
}

const styles = {
   container: {
      width: '100%',
      margin: 0,
      padding: 0,
      background: 'white',
      padding: '5px',
      color: 'black',
      fontSize: '9pt',
      fontFamily: "Roboto, sans-serif"
   },
   brand: {
      textAlign: 'center',
      fontSize: '1.5rem',
      color: 'black',
      fontWeight: 'bold'
   },
   basicInfo: {
      display: 'flex',
      justifyContent: 'space-between',
   },
   dateTime: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'right'
   },
   dottedHr: {
      borderTop: '2px dotted #000',
      height: 0,
      width: '100%',
      marginTop: '10px',
      marginBottom: '10px'
   },
   tbh: {
      display: 'flex',
      justifyContent: 'space-between',
   },
   betMatch: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '10px'
   },
   textLeft: {
      textAlign: 'left'
   },
   textCenter:{
      textAlign:'center'
   },
   textRight: {
      textAlign: 'right'
   },
   result: {
      display: 'flex',
   },
   label: {
      flex: 1,
      textAlign: 'right'
   },
   amounts: {
      flex: 1,
      textAlign: 'right'
   }
}