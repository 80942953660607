import React from 'react';
import { Table, Button } from 'antd';
import {
      PrinterFilled,
      ArrowLeftOutlined,
} from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { ThreeDBetToPrint } from './ThreeDBetToPrint';
import { FourDBetToPrint } from './FourDBetToPrint';

const fourDColumns = [
      {
            title: 'ဂဏန်း',
            dataIndex: 'number',
            key: 'number',
            render: (text, record) => record?.fourDNumber?.number
      },
      {
            title: 'ထိုးငွေ',
            dataIndex: 'userBetAmount',
            key: 'userBetAmount',
            render: (text, record) => record?.userBetAmount
      },
]

const threeDColumns = [
      {
            title: 'ဂဏန်း',
            dataIndex: 'number',
            key: 'number',
            render: (text, record) => record?.threeDNumber?.number
      },
      {
            title: 'အဆ',
            dataIndex: 'winPercent',
            key: 'winPercent',
      },
      {
            title: 'ထိုးငွေ',
            dataIndex: 'userBetAmount',
            key: 'userBetAmount',
      }
]


class BetDetail extends React.Component {
      render() {
            const { bet_id } = this.props.match?.params;
            const filteredBet = this.props.activeBets?.filter(el => `${el.id}` === bet_id);
            const bet = filteredBet.length > 0 ? filteredBet[0] : null;
            return (
                  <>
                        <div style={styles.container}>
                              <div style={styles.buttonWrapper}>
                                    <Button
                                          style={{ marginRight: 'auto' }}
                                          icon={<ArrowLeftOutlined />}
                                          type="primary"
                                          onClick={() => this.props.history.goBack()}
                                    >
                                          Back
                                    </Button>
                              </div>
                              <br />
                              <div style={styles.wrapper}>
                                    <div style={styles.top}>
                                          <span>
                                                Bet : {`${bet_id}`}
                                          </span>
                                          <span>
                                                Bet : {bet?.userBetAmount}
                                          </span>
                                          <span>
                                                Commission : {bet?.userCommissionAmount}
                                          </span>
                                          <span>
                                                Win : {bet?.userWinAmount}
                                          </span>
                                          <ReactToPrint
                                                trigger={() => <Button
                                                      icon={<PrinterFilled />}
                                                      style={{ borderColor: 'blue', color: 'blue' }}>
                                                      PRINT
                                                </Button>}
                                                content={() => this.componentRef}
                                          />
                                          <div style={{ display: 'none' }}>
                                                {bet?.type === 'THREED' ? <ThreeDBetToPrint
                                                      ref={el => this.componentRef = el}
                                                      bet={bet} /> :
                                                      <FourDBetToPrint
                                                            ref={el => this.componentRef = el}
                                                            bet={bet} />}
                                          </div>
                                    </div>
                                    <Table
                                          columns={bet?.type === 'THREED' ? threeDColumns : fourDColumns}
                                          dataSource={bet?.type === 'THREED' ? bet?.betThreeDNumbers
                                                : bet?.betFourDNumbers}
                                    />
                              </div>
                        </div>
                  </>
            );
      }
}

const mapStateToProps = state => ({
      activeBets: state.activeBet.twoThreeFour,
})

export default withRouter(connect(mapStateToProps, null)(BetDetail));

const styles = {
      container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '5px'
      },
      wrapper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: '15px',
            borderRadius: '5px',
            boxShadow: '0px 2px 8px -1px gray',
            width: '75vw',
            minWidth: '500px',
            padding: '15px',
            boxSizing: 'border-box'
      },
      top: {
            display: 'flex',
            gap: '60px',
            fontSize: '16px'
      },
      buttonWrapper: {
            width: '75vw',
            minWidth: '500px',
      }
}