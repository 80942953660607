import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';
import { getCurrentUser } from '../user/getCurrentUser';

export const betFourD = ({
      collectionId,
      betFourDNumbers
}) => async dispatch => {
      dispatch({
            type: actionTypes.FOURD_BET_REQUEST,
      });
      const options = {
            method: 'POST',
            url: '/fourDBets',
            data: {
                  collectionId,
                  betFourDNumbers
            }
      }
      
      await axios(options)
            .then(response => {
                  dispatch({
                        type: actionTypes.FOURD_BET_SUCCESS,
                        payload: response.data
                  });
                  dispatch(getCurrentUser())
            })
            .catch(error => {
                  dispatch({
                        type: actionTypes.FOURD_BET_FAIL,
                        payload: getMessage(error)
                  })
            });
}