import React from 'react';
import moment from 'moment';
import { homeOddPrice, awayOddPrice } from './oddPrice';
// import {dummy} from './dummy'

export class AllMatchesToPrint extends React.Component {
   getMatch = (match) => {
      return (
         <>
            <div style={styles.dottedHr} />
            <div style={styles.match}>
               <div style={{ flex: 1 }}>
                  {match?.homeNo}.{match?.home.name}<br />
                  {homeOddPrice(match)}
               </div>
               <div style={{
                  flex: 1,
                  textAlign: 'center'
               }}>
                  Over
               </div>
               <div>
                  {moment(match?.startTime).format('hh:mma')}<br />
                  <div style={{ width: '50px' }}>
                     ({match?.goalTotal}{match?.goalTotalPrice >= 0 ?
                        `+${match?.goalTotalPrice}` :
                        match?.goalTotalPrice})
                  </div>
               </div>
               <div style={{
                  flex: 1,
                  textAlign: 'center'
               }}>
                  Under
               </div>
               <div style={{ flex: 1 }}>
                  {match?.awayNo}.{match?.away.name}
                  <br />
                  {awayOddPrice(match)}
               </div>
            </div>
         </>
      );
   }
   render() {
      const title = localStorage.getItem("555mixPOSReceipt");
      return (
         <>
            <div style={styles.container}>
               <h1 style={styles.brand}>{title}</h1>
               <div style={styles.date}>
                  Date:{moment().format('DD/MM/YYYY')}
               </div>
               {this.props.matches?.map(this.getMatch)}
               {/* {dummy.map(this.getMatch)} */}
            </div>
         </>
      );
   }
}

const styles = {
   container: {
      width: '100%',
      margin: 0,
      padding: 0,
      background: 'white',
      padding: '5px',
      color: 'black',
      fontSize: '8pt',
      fontFamily: "Roboto, sans-serif"
   },
   brand: {
      textAlign: 'center',
      fontSize: '1.5rem',
      color: 'black',
      fontWeight: 'bold'
   },
   date: {
      width: '100%',
      textAlign: 'right',
   },
   dottedHr: {
      borderTop: '2px dotted #000',
      height: 0,
      width: '100%',
      marginTop: '10px',
      marginBottom: '10px'
   },
   match: {
      width: '100%',
      display: 'flex',
   }
}